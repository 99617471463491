
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body, label, .checkbox label {
  font-weight: 300;
}

.btn-file-input {
  position: relative;
  overflow: hidden;
}
.btn-file-input input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}

.weight-log-preview {
  display:inline-block;
  position:relative;
  img {
    max-width: 100%;
  }
}
.weight-banner {
  position:absolute;
  font-size:28px;

  right:-10px;
  top:10px;
  span {
    color:#ff0;
  }
}
